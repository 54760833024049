import {getConfigValue, requireConfigValues} from '../libs/@elements/config-utils';
import initModulesInScope from '../libs/@elements/init-modules-in-scope';
import {showNotification, clearAll} from '../libs/@elements/alert-notification';
import 'url-search-params-polyfill'; // Edge Polyfill
import fetch from "../libs/@elements/fetch"
import formDataEntries from 'form-data-entries';
import {responseTracking} from "../libs/@elements/tracking";

const configName = '_cartConfig';

let pendingRequest,
    $cartNotificationResult = $('.js-cart__notification-result'),
    $count,
    timeoutHandle;

export function init(){
    $count = $('.js-cart__count');

    // initial cart list count
    if (($count && $count.length) ) {
        requireConfigValues(['cartInfoUrl'], configName);

        let request = fetch(getConfigValue('cartInfoUrl', configName), {
            method: 'get',
        });

        showNotification(request);

        request.then(response => response.json())
            .then(result => {
                if (result.success) {
                    if (typeof result.count !== "undefined") {
                        setCount($count, result.count);
                    }
                }
            }).catch(e => {
            console.warn(e);
        });
    }
}

export function initInScope($scope, {onAdd, onAddSucceed, onAddFailed} = {}) {
    let $cartForm = $scope.find('.js-cart__form');
    let $cartRemove = $scope.find('.js-cart__remove');
    let $cartLoading = $('.js-cart__loading');
    let $cartResult = $('.js-cart__result');

    if ($cartForm && $cartForm.length) {

        let formTrigger = $cartForm.data('trigger') || 'submit';
        let emptyCart = $cartForm.data('empty-cart');
        let showCartModal;

        requireConfigValues(['cartUrl'], configName);

        $cartForm.each(function () {
            const $thisForm = $(this);
            const $cartNotifications = $thisForm.find('.js-cart__notifications');
            const hasContainer = !!$cartNotifications.length;

            let redirect = false;
            $thisForm.find('.js-cart__form-submit').on('click', function () {
                redirect = $(this).data('redirect-url');
            });

            $thisForm.on('click', '.cart-list__item', function(ev) {
                let data = new URLSearchParams(formDataEntries($cartForm[0]));
                let request = fetch(getConfigValue('cartListSetSelectedBasket', configName), {
                    method: 'post',
                    body: data,
                });

                request.then(response => response.json())
                    .then(result => {
                        if (result.success) {
                        }
                    }).catch(e => {
                        console.warn(e);
                    }
                );
            });
            
            $thisForm.on(formTrigger, function (evt) {
                evt.preventDefault();
                let closeModal = $(evt.originalEvent.submitter).data('close-modal');
                let $thisSubmittedForm = $(this);

                showCartModal = $(this).data('show-cart-modal');

                const data = new URLSearchParams(formDataEntries($thisForm[0]));

                if ($thisForm.data('additional-form')) {
                    let targetForm = $($thisForm.data('additional-form'));
                    targetForm.find('.js-variant-select__item-amount').each(function (index, input) {
                        data.append($(input).attr('name'), $(input).val());
                    });
                }

                if ($thisSubmittedForm.data('cart-select-modal')) {
                    //load cart select modal
                    const $modal = $($thisSubmittedForm.data('modal-target'));
                    $modal.modal();

                    $modal.find('.js-cart-modal__loading').attr('hidden', null);

                    let request = fetch(getConfigValue('cartListSelectionUrl', configName), {
                        method: 'post',
                        body: data
                    });

                    $modal.find(".js-cart-modal__result").empty();

                    request.then(response => response.json())
                        .then(result => {
                            if (result.success) {
                                let content = result.content || result.html;

                                if (content) {
                                    $modal.find(".js-cart-modal__result").html(content);

                                    initModulesInScope($modal);
                                }

                                $modal.find('.js-cart-modal__loading').attr('hidden', 'hidden');
                            }
                        }).catch(e => {
                            console.warn(e);
                        }
                    );

                } else {
                    //add to cart
                    let modalParent = $thisForm.closest('.js-cart-modal');
                    if (modalParent) {
                        modalParent.find('.js-cart-modal__loading').attr('hidden', null);
                    }

                    $cartLoading.attr('hidden', null);

                    if(emptyCart){
                        $cartResult.attr('hidden', 'hidden');
                    }

                    if(hasContainer) {
                        clearAll({
                            $container: $cartNotifications
                        });
                    }

                    $cartNotificationResult.attr('hidden', 'hidden');

                    call(onAdd);

                    let request = fetch(getConfigValue('cartUrl', configName), {
                        method: 'post',
                        body: data,
                    });

                    if(hasContainer) {
                        showNotification(request, {
                            $container: $cartNotifications
                        });
                    }

                    responseTracking(request);

                    // if (modalParent) {
                    //     showNotification(request, {
                    //         $container: modalParent.find('.js-cart-modal__notifications')
                    //     });
                    // }

                    request.then(response => response.json())
                        .then(result => {
                            if (result.success) {
                                if (modalParent) {
                                    modalParent.find('input[name=cartId]:checked').siblings('.cart-list__item-count').text(result.count);
                                    modalParent.find('.js-cart-modal__loading').attr('hidden', 'hidden');
                                    if (closeModal === true && result.added) {
                                        modalParent.modal('hide');
                                    }
                                }

                                if(result.availabilityStatus && $thisForm.data("update-availability")) {
                                    $(".js-product-availability-status").html(result.availabilityStatus);
                                }

                                if(showCartModal || !result.added){
                                    const $modal = $($thisSubmittedForm.data('modal-target'));
                                    $modal.modal();

                                    let content = result.content || result.html;

                                    if (content) {
                                        $modal.find(".js-cart-modal__result").html(content);

                                        initModulesInScope($modal);
                                    }

                                    $modal.find(".js-cart-modal__accessories").css("display", result.added ? "block" : "none");
                                }else{
                                    window.clearTimeout(timeoutHandle);
                                    setCartNotification($cartNotificationResult, result.cartNotificationContent);
                                }

                                if (typeof result.count !== "undefined") {
                                    setCount($count, result.count);
                                }

                                call(onAddSucceed);

                                if (redirect && redirect.length > 0) {
                                    modalParent.find('.js-cart-modal__loading').attr('hidden', false)
                                    window.location.href = redirect;
                                }
                            } else {
                                call(onAddFailed);
                            }
                        }).catch(e => {
                            console.warn(e);
                            $cartLoading.attr('hidden', 'hidden');
                            $cartResult.attr('hidden', 'hidden');
                            $cartNotificationResult.attr('hidden', 'hidden');
                            call(onAddFailed);
                        }
                    );
                }
            });
        });


        // remove product from cart
        $cartRemove.on('click', function (evt) {
            evt.preventDefault();
            $(this).closest('form').find('.js-cart__amount').val('0').trigger('change');
        });
    }
}

function setCount($element, count) {
    if (count) {
        $element.attr('hidden', null);
        $element.text(count);
    } else {
        $element.attr('hidden', 'hidden');
    }
}

function setCartNotification($cartNotificationResult, content) {
    if (content) {
        $cartNotificationResult.empty().append(content);
        $cartNotificationResult.attr('hidden', null);
        initModulesInScope($cartNotificationResult);

        timeoutHandle = window.setTimeout(function(){
            $cartNotificationResult.attr('hidden', 'hidden');
        }, 3000);

    } else {
        $cartNotificationResult.attr('hidden', 'hidden');
    }
}

// Call the given function if it really is one
function call(fnc, ...params) {
    if (fnc && typeof fnc === 'function') {
        fnc(...params);
    }
}
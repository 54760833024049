"use strict";

export function initInScope ($scope) {
    let $videos = $scope.find('.js-video__video');
        $videos.each(function () {
            let id = $(this).prop('id'),
                $container = $(this).closest('.js-video'),
                $playButton = $container.find('.js-video__play');

            if (!id) {
                id = 'video-id-' + currentId++;
                $(this).prop('id', id);
            }

            let video = $(this);

            //fix mobile duplicate buttons
            if (matchMedia('(max-width: 767px)').matches && $playButton.length) {
                video.attr('controls', false);
            }

            video.on("playing", function(){
                $container.addClass('is-playing');
            });

            video.on('ended', function () {
                $container.removeClass('is-playing');
            });

            $playButton.on('click', function () {
                video[0].play();
            });

            video.on('contextmenu', function (evt) {
                evt.preventDefault();
            });
        });
}